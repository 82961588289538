import { JsonLd } from 'react-schemaorg';

function Sitelinks() {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: 'https://digitalnagalerija.ba',
    name: 'Prva BH Digitalna Galerija',
    breadcrumb: {
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: 'Početna',
          item: 'https://digitalnagalerija.ba/'
        },
        {
          '@type': 'ListItem',
          position: 2,
          name: 'O nama',
          item: 'https://digitalnagalerija.ba/onama'
        },
        {
          '@type': 'ListItem',
          position: 3,
          name: 'Profili naših umjetnika',
          item: 'https://digitalnagalerija.ba/pu'
        },
        {
          '@type': 'ListItem',
          position: 4,
          name: 'Galerija',
          item: 'https://digitalnagalerija.ba/g'
        },
        {
          '@type': 'ListItem',
          position: 5,
          name: 'Predstavite svoju umjetnost',
          item: 'https://digitalnagalerija.ba/rd'
        }
      ]
    }
  };

  return (
    <JsonLd item={schema}>
      <ul>
        <li>
          <a href="https://digitalnagalerija.ba/">Početna</a>
        </li>
        <li>
          <a href="https://digitalnagalerija.ba/onama">O nama</a>
        </li>
        <li>
          <a href="https://digitalnagalerija.ba/pu">Profili naših umjetnika</a>
        </li>
        <li>
          <a href="https://digitalnagalerija.ba/g">Galerija</a>
        </li>
        <li>
          <a href="https://digitalnagalerija.ba/rd">Predstavite svoju umjetnost</a>
        </li>
      </ul>
    </JsonLd>
  );
}


export default Sitelinks;