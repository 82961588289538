import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './Subscription.css'; // Import your CSS file

function Subscription() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [highlightedPlan, setHighlightedPlan] = useState('osnovni'); // Default highlighted plan

  const plans = [
    {
      name: 'standard_package', 
      price: '340 KM',
      features: [
        'personal_profile',
        'up_to_30_artworks',
        '21_days_promotion',
        '4_blog_posts',
        'social_media_promotion',
        'featured_blog_posts_10', 
        '3_custom_designs',
        '3_perfect_gift_posts',
        '2_homepage_posts'
      ],
    },
    {
      name: 'premium_package',
      price: '420 KM',
      features: [
        'personal_profile',
        'unlimited_artworks',
        '21_days_promotion',
        '6_blog_posts',
        'social_media_promotion',
        'video_presentation',
        'featured_blog_posts_15',
        '7_custom_designs',
        '5_perfect_gift_posts',
        '3_homepage_posts'
      ],
    },
    {
      name: 'student_package',
      price: '85 KM',
      features: [
        'personal_profile',
        'gallery_artworks',
        '2_blog_posts' 
      ],
    },
    {
      name: 'special_offer',
      price: '180 KM',
      features: [
        '15_days_exhibition',
        'gala_night',
        'paid_ads',
        'blog_post',
        'tv_appearance',
        '10_percent_sales'
      ],
    }
  ];

  const handlePlanClick = (planName) => {
    navigate('/pr', { state: { selectedPlan: planName } }); // Redirect with plan data
  };

  return (
    <section className="subscription">
      <h2>{t('choose_plan')}</h2>
      <h3 className="desctiption">{t('plan_description')}</h3>
      <div className="subscription-cards">
        {plans.map(plan => (
          <div
            key={plan.name}
            className={`subscription-card ${highlightedPlan === plan.name ? 'highlighted' : ''}`}
            onMouseEnter={() => setHighlightedPlan(plan.name)}
            onMouseLeave={() => setHighlightedPlan('')}
          >
            <h3>{t(plan.name)}</h3>
            <p className="price">{plan.price} {t('per_year')}</p>
            <ul>
              {plan.features.map((feature, index) => (
                <li key={index}>{t(feature)}</li>
              ))}
            </ul>
            <button onClick={() => handlePlanClick(plan.name)}>{t('subscribe')}</button>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Subscription;
