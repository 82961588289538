import React, { useState } from 'react';
import './FAQPage.css';
import { useTranslation } from 'react-i18next';


function FAQPage() {
  const { t } = useTranslation();

  const [activeIndex, setActiveIndex] = useState(null);

  const faqData = [
    {
      question: 'Šta je zamisao iza digitalne umjetničke galerije?',
      answer: 'Digitalna umjetnička galerija je online platforma koja vam omogućava da prodajete svoju digitalnu umjetnost bez potrebe za vlastitom web stranicom ili tehničkom infrastrukturom.',
    },
    {
      question: 'Kako mogu postaviti svoju umjetnost na platformu?',
      answer: 'Kreiranje profila je jednostavno! Kontaktirajte nas i mi ćemo za Vas uraditi sve, od postavljanja slika, konfigurasanja profila, do kreiranja marketing kampanje.',
    },
    {
      question: 'Koje su prednosti korištenja ove platforme?',
      answer: 'Naša platforma vam nudi centralno mjesto gdje možete ponuditi svojim kupcima Vaše umjetnine.',
    },
    {
      question: 'Kako ćete osigurati da moja umjetnina zaista bude ponuđenja tržištu?',
      answer: '50% od pretplate na našoj platformi se koristi isključivo za promoviranje Vaših radova. Svakog mjeseca ćete dobiti izvještaj na Vaš email o uspješnosti kampanje.',
    },
    {
      question: 'Postoji li provizija za prodaju?',
      answer: 'Ne, mi ne uzimamo proviziju na Vaše prodaje.',
    },
    {
      question: 'Koje dodatne usluge nudite?',
      answer: 'Jako smo ponosni na naš certifikat o vlasništvu, kojeg svaki kupac dobija uz kupljeni rad. Na ovaj način mi možemo reći da rješavamo dugogodišnji problem prodaje orginalnih radova bez potvrda o porijeklu.',
    },
    {
      question: 'Moji radovi nemaju okvire, da li je to problem?',
      answer: 'Jako smo ponosni što možemo ponuditi minimalističke okvire po jako konkurentnim cijenama kao dodatne usluge platforme.',
    }, 
    {
      question: 'Koji je minimum umjetnina koje mogu ponuditi na svom profilu?',
      answer: 'Nema minimuma, možete izložiti vaše radove koji više i nisu na prodaju.',
    },
    {
      question: 'Kako maksimizirati šansu za prodaju radova?',
      answer: 'Prije svega dobra vidljivost rada na društvenim mrežama je osnov za uspjeh. Naredni korak jeste kvalitetan opis, slika rada, te rad na imenu umjetnika. Ovaj posljednji dio je dugoročan proces, zahtjeva redovan angažman na platformi kroz pisanje blogova, te kroz vlastite društvene mreže-',
    }
  ]; // Mocked podaci

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="faq-page">
    <h2>{t('faq_0')}</h2>
    <div className="faq-list">
      {faqData.map((faq, index) => (
        <div 
          key={index} 
          className={`faq-item ${activeIndex === index ? 'active' : ''}`}
          onClick={() => toggleFAQ(index)}
        >
          <div className="faq-question">
            {t(`faq_${index + 1}_q`)} 
            <span className={`faq-arrow ${activeIndex === index ? 'faq-arrow-up' : 'faq-arrow-down'}`}></span>
          </div>
          {activeIndex === index && (
            <div className="faq-answer">
              {t(`faq_${index + 1}_a`)} 
            </div>
          )}
        </div>
      ))}
    </div>
  </div>
  );
}

export default FAQPage;
