import ArtistCard from './Profile.js';
import React, { useState, useEffect } from 'react';
import './ProfilePage.css'
import Spacer from '../components/Spacer.js';
import { useTranslation } from 'react-i18next';



function ArtistList({ }) {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true); // Add loading state
    const [artists, setArtists] = useState([]);

    useEffect(() => {

        const API_URL = process.env.NODE_ENV === 'production'
            ? 'https://digitalnagalerija.ba'
            : 'http://localhost:3001';

        setIsLoading(true); // Set loading to true before fetching
        fetch(`${API_URL}/api/artists`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setArtists(data);
                setIsLoading(false);
                console.log('data', data)
            })
            .catch(error => console.error('Error fetching artists:', error));
    }, []);

    if (isLoading) {
        return <div>Loading...</div>; // Show loading message while fetching
    }
    return (
        <>
            <h2 className="artist-list-title">{t('artist_list_title')}</h2>
            <p className="artist-list-intro">{t('artist_list_intro')}</p>

            <div className="artist-list" >
                {artists.map(artist => (
                    <ArtistCard key={artist.id} artist={artist} />
                ))}
            </div>
            <Spacer height={80} />

        </>
    );
}

export default ArtistList;
