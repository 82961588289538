import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './BlogDetails.css'; 
import { useLocation } from 'react-router-dom';

function BlogDetails() {
  const { blogId } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation(); 
  const blog = state?.blogData; 


  const handleBackClick = () => {
    navigate(-1);
  };

  if (!blog) {
    return <div>Blog post not found</div>;
  }

  return (
    <section className="blog-details">
      <p className='blog-text'>USKORO</p>
      {/* <div className="blog-content">
        <img src={blog.imageUrl} alt={blog.name} className="blog-image" />
        <div className="blog-info">
          <div className="author-info">
            <img src={blog.authorImage} alt={blog.author} className="author-image" />
            <p className="author-name">{blog.author}</p>
          </div>
          <h2>{blog.name}</h2>
          <p className="blog-date">{blog.date}</p> 
          <p className="blog-text">{blog.text}</p>
          <p className="blog-text">{blog.text}</p>
          <p className="blog-text">{blog.text}</p>
          <p className="blog-text">{blog.text}</p>

        </div>
      </div> */}
    </section>
  );
}

export default BlogDetails;
