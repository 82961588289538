import English from '../locale/en.json';
import Bosnian from '../locale/bs.json';
import i18n from "i18next";
import { initReactI18next } from "react-i18next"; 


i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: English 
      },
      bs: {
        translation: Bosnian
      }
    },
    lng: "en", // Initial language
    fallbackLng: "en",
    interpolation: {
      escapeValue: false 
    }
  });

export default i18n; 
