import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './CookieBar.css';
import { useTranslation } from 'react-i18next';


function CookieBar() {
    const [isAccepted, setIsAccepted] = useState(null); // Initially null while checking
    const [isLoading, setIsLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        // Simulate an asynchronous cookie check (replace with your actual logic)
        setTimeout(() => {
            const consent = Cookies.get('cookieConsent');
            setIsAccepted(consent === 'accepted');
            setIsLoading(false);
        }, 500); // Adjust the delay as needed
    }, []);

    const handleAccept = () => {
        Cookies.set('cookieConsent', 'accepted', { expires: 365 });
        setIsAccepted(true);
    };

    return (
        (isLoading || !isAccepted) && ( // Show while loading or not accepted
            <div className={`cookie-bar ${!isAccepted ? 'show' : ''}`}>
                {isLoading ? (
                    <p>Loading...</p> // Or a loading indicator
                ) : (
                    <>
                        <p>
                           {t("cookie1")}
                            <a href="/privacy-policy">{t("learn_more")}</a>
                        </p>
                        <button onClick={handleAccept}>{t("accept")}</button>
                    </>
                )}
            </div>
        )
    );
}

export default CookieBar;
