import React, { createContext, useState, useEffect } from 'react';
import i18n from 'i18next';

const TranslationContext = createContext({
  currentLanguage: 'bs', // Set default to 'bs'
  toggleLanguage: () => {},
});

export const TranslationProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState('bs'); // Initial state

  useEffect(() => {
    // Check if user has a preferred language set
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      setCurrentLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    } else {
      // If not, set Bosnian as default
      i18n.changeLanguage('bs'); 
    }
  }, []); // Run only once when component mounts

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === 'bs' ? 'en' : 'bs';
    i18n.changeLanguage(newLanguage);
    setCurrentLanguage(newLanguage);
    // Store the selected language in localStorage for future visits
    localStorage.setItem('language', newLanguage);
  };

  return (
    <TranslationContext.Provider value={{ currentLanguage, toggleLanguage }}>
      {children}
    </TranslationContext.Provider>
  );
};

export default TranslationContext;
