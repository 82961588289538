import React, { useState } from 'react';
import './Login.css'; // Your CSS file
import { useTranslation } from 'react-i18next';


function Login() {
  const { t } = useTranslation();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(''); // For error messages

  const handleSubmit = (e) => {
    e.preventDefault();
    // Basic client-side validation
    if (!username || !password) {
      setError('Molimo Vas, unesite vaše podatke ponovo ili pokušajte kasnije.');
      return;
    }

    // TODO: Send login credentials to your backend for authentication
    // ...

    // Clear error if successful login
    setError(''); 
  };

  return (
    <div className="login-container">
    <h2>{t('login_title')}</h2>
    <form onSubmit={handleSubmit}>
      <div className="input-group">
        <label htmlFor="username">{t('username')}</label>
        <input 
          type="text" 
          id="username" 
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
      </div>
      <div className="input-group">
        <label htmlFor="password">{t('password')}</label>
        <input 
          type="password" 
          id="password" 
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>
      {error && <p className="error-message">{t(error)}</p>}
      <button type="submit">{t('continue')}</button>
    </form>
  </div>
  );
}

export default Login;
