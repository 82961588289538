import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './Gallery.css';

import image from '../fonts/logo bez teksta bijeli.png'


function GalleryCard({ artwork }) {
    const navigate = useNavigate();

    const [cardHeight, setCardHeight] = useState(null);
    const cardRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false); // State for visibility
    const artistListRef = useRef(null); // Ref for the artist list container

    useEffect(() => {
        const minHeight = 250;
        const maxHeight = 290;

        // Shorter height for every third artist ID
        const height = artwork.id % 3 === 0 ? minHeight : (Math.floor(Math.random() * (maxHeight - minHeight + 1)) + minHeight);

        setCardHeight(height);

        console.log('gallery artwork', artwork.title)

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5 // Trigger when 50% of the item is visible
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            });
        }, options);

        if (artistListRef.current) {
            observer.observe(artistListRef.current);
        }

        return () => {
            if (artistListRef.current) {
                observer.unobserve(artistListRef.current);
            }
        };
    }, [artistListRef]); // Use artist.id in the dependency array





    return (
        <Link
            to={`/p/${artwork._id}`} state={{ artwork }}
            className="artist-card" // Apply styling to the Link
        >
            <div className="image-container">
                <img
                    src={artwork.imageUrl || image}
                    alt={artwork.title}
                    className="artist-image artwork-thumbnail"
                />
                <div className="image-overlay">
                    <h3 className="artist-name">{artwork.title}</h3>
                </div>
            </div>
        </Link>
    );
}

export default GalleryCard;
