import React, { useRef, useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './Home.css';
import CookieBar from '../components/CookieBar.js';
import data from './mocked_art_data.js';
import NewsLetter from '../components/Newsletter.js';
import BackgroundVideo from '../components/BackgroundVideo.js';
import video from '../fonts/artworkvideo.mp4';

import Carousel from 'react-elastic-carousel';


import { useTranslation } from 'react-i18next';
import TranslationContext from '../context/TranslationContext';
import i18n from '../context/i18n';




function Home() {
  const navigate = useNavigate();
  const firstCardRef = useRef(null);
  const secondCardRef = useRef(null);

  const { t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(TranslationContext);

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === 'en' ? 'bs' : 'en';
    i18n.changeLanguage(newLanguage);
    setCurrentLanguage(newLanguage);
  };

  const thirdCardRef = useRef(null);  // Reference for the third card


  const cardRefs = useRef([]);



  const [carouselIndex, setCarouselIndex] = useState(0);
  const carouselItems = [
    data.featuredArtist, data.featuredArtist2, data.featuredArtist3
  ];

  const blogItems = [
    data.fetchLatestPost, data.fetchLatestPost2, data.fetchLatestPost3
  ];

  const giftItems = [
    data.artGift, data.artGift2, data.artGift3
  ];

  // ... (your observer logic for fade-in animations)

  const handleSlideSelect = (selectedIndex) => {
    setCarouselIndex(selectedIndex);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            // Add a class to trigger the animation
            entry.target.classList.add('card-visible');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.2 } // Adjust the threshold as needed
    );

    // Observe all cards (you might need to adjust the querySelector)
    document.querySelectorAll('.card').forEach((card) => {
      observer.observe(card);
    });


    return () => {
      cardRefs.current.forEach((ref) => {
        if (ref) { // Check if ref exists before unobserving
          observer.unobserve(ref);
        }
      });
    };
  }, []);


  const carouselRef = useRef(null);

  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === carouselItems.length - 1) {
      // If at the last item, go back to the first one without animation
      setTimeout(() => {
        carouselRef.current.goTo(0, false);
      });
    }
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 }, // Mobile - show 1 item
    { width: 768, itemsToShow: 3 }, // Desktop - show 3 items
  ];

  const handleButtonClick = (path) => {
    navigate(path);
  };


  return (
    <section className="hero">
      <BackgroundVideo video={video} className="video-gallery" />
      <CookieBar />
      <div className="welcome-card" ref={firstCardRef}>
        <h1>{t('PRVA_BH_DIGITALNA_GALERIJA')}</h1>
        <button className="welcome-button" onClick={() => handleButtonClick('/g')}>{t('Istraži_Galeriju')}</button>
      </div>
      <div className="container">
        <div className="mission-card card" ref={secondCardRef}>
          <h2>{t('BH_umjetnici_na_jednom_mjestu')}</h2>
          <p>{t('Sada_možete_uživati_u_umjetninama_naših_BH_umjetnika_na_jednom_mjestu')}</p>
          <button className="cta-button" onClick={() => handleButtonClick('/pu')}>
            {t('Pogledaj_umjetnike')}
          </button>
        </div>
        <div className="mission-card card" ref={secondCardRef}>
          <h2>{t('Kupite_najbolji_poklon_za_vašu_voljenu_osobu')}</h2>
          <p>{t('Otkrijte_savršen_poklon_spreman_za_pošiljku_na_vašu_adresu')}</p>
          <button className="cta-button" onClick={() => handleButtonClick('/sp')}>
            {t('Savršen_poklon')}
          </button>
        </div>
        <div className="mission-card card" ref={secondCardRef}>
          <h2>{t('Predstavite_vaše_radove')}</h2>
          <p>{t('Javite_nam_se_i_započnimo_saradnju_ponudite_svoje_radove_na_ovoj_platformi')}</p>
          <button className="cta-button" onClick={() => handleButtonClick('/rd')}>
            {t('Pogledaj_ponudu')}
          </button>
        </div>

        <div className="carousel-container">
          <Carousel
            ref={carouselRef}
            showDots={true}
            showArrows={false}  // Disable the built-in arrows
            enableAutoPlay={false}
            enableMouseSwipe={false}// Disable user swipe
            itemsToShow={1}
            autoPlaySpeed={3000}
            onNextStart={onNextStart}
            breakPoints={breakPoints}
          >
            {carouselItems.map((item, index) => (

              <div className="featured-artists-card card">
                <h2 className='test'>{t('Predstavljamo_vam_ovog_mjeseca')}</h2>
                <div className="artist-info">
                  <img className='image-test' src={item.imageUrl} alt={item.name} />
                  <h3>{item.name}</h3>
                  <p>{item.description}</p>
                  <button className="cta-button" onClick={() => handleButtonClick(item.id)}>
                    {t('POSJETI_PROFIL')}
                  </button>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
        <div className="carousel-container">
          <Carousel
            showDots={true}
            showArrows={false}  // Disable the built-in arrows
            enableAutoPlay={false}
            enableMouseSwipe={false}// Disable user swipe
            itemsToShow={1}
            autoPlaySpeed={3000}
            breakPoints={breakPoints}
          >
            {blogItems.map((item, index) => (

              <div className=" featured-artists-card card">
                <h2 className='test'>{t('Sedmični_Blog')}</h2>
                <div className="artist-info">
                  <img className='image-test' src={item.imageUrl} alt={item.name} />
                  <h3>{item.name}</h3>
                  <p>{item.excerpt}</p>
                  <button className="cta-button" onClick={() => handleButtonClick(item.id)}>
                    {t('POSJETI_PROFIL')}
                  </button>
                </div>
              </div>
            ))}
          </Carousel>
        </div>

        <div className={`mission-card card`} ref={thirdCardRef}>
          <h2>{t('Predstavite_svoju_umjetnost_na_platformi')}</h2>
          <p>{t('Pogledajte_našu_ponudu_po_različitim_cijenama_kako_biste_saznali_više_o_uslovima_korištenja_platforme')}</p>
          <button className="cta-button" onClick={() => handleButtonClick('/rd')}>
            {t('Pretplatite_se')}
          </button>
        </div>
        <div className={`mission-card card`} ref={thirdCardRef}>
          <h2>{t('Najavite_vašu_izložbu_na_jedinstvenoj_pratformi')}</h2>
          <p>{t('Sa_ciljem_širenja_zajednice_koja_se_veže_za_umjetnost_i_naše_BH_umjetnika_na_ovoj_stranici_možete_najaviti_vašu_izložbu')}</p>
          <button className="cta-button" onClick={() => handleButtonClick('/rd')}>
            {t('Pretplatite_se')}
          </button>
        </div>

        <div className={`mission-card card`} ref={thirdCardRef}>
          <h2>{t('Umjetniku_prilazimo_sa_posebnom_pažnjom')}</h2>
          <p>{t('Naš_tim_je_tu_da_sve_vaše_potrebe_na_platformi_realizira')}</p>
          <button className="cta-button" onClick={() => handleButtonClick('/rd')}>
            {t('Pretplatite_se')}
          </button>
        </div>





        <div className={`mission-card card`} ref={thirdCardRef}>
          <h2>{t('Najavite_vašu_novu_izložbu_preko_naše_platforme')}</h2>
          <p>{t('Preko_naše_platforme_možete_najaviti_novosti_koje_želite_podijeliti_sa_svojim_pratiteljima')}</p>
          <button className="cta-button" onClick={() => handleButtonClick('/rd')}>
            {t('Pogledajte_kako')}
          </button>
        </div>

        <div className={`mission-card card`} ref={thirdCardRef}>
          <h2>{t('Najavite_vašu_novu_izložbu_preko_naše_platforme')}</h2>
          <p>{t('Preko_naše_platforme_možete_najaviti_novosti_koje_želite_podijeliti_sa_svojim_pratiteljima')}</p>
          <button className="cta-button" onClick={() => handleButtonClick('/rd')}>
            {t('Pogledajte_kako')}
          </button>
        </div>

        <div className={`mission-card card`} ref={thirdCardRef}>
          <h2>{t('Najavite_vašu_novu_izložbu_preko_naše_platforme')}</h2>
          <p>{t('Preko_naše_platforme_možete_najaviti_novosti_koje_želite_podijeliti_sa_svojim_pratiteljima')}</p>
          <button className="cta-button" onClick={() => handleButtonClick('/rd')}>
            {t('Pogledajte_kako')}
          </button>
        </div>




        <div className="mission-card card">
          <h2>{t('Spojite_svoj_Behance_Instagram_Facebook_profil_sa_galerijom')}</h2>
          <div className="connect-info">
            <p className='connect-p'>{t('Uz_našu_pomoć_možete_spojiti_sve_vaše_profile_na_jednom_mjestu_tako_da_svi_vaši_zainteresirani_klijenti_mogu_pogledati_radove_i_na_drugim_mjestima')}</p>
          </div>
          <button className="cta-button" onClick={() => handleButtonClick('/rd')}>
            {t('Pogledaj_našu_ponudu')}
          </button>
        </div>
        <div className="carousel-container">
          <Carousel
            showDots={true}
            showArrows={false}  // Disable the built-in arrows
            enableAutoPlay={false}
            enableMouseSwipe={false}// Disable user swipe
            itemsToShow={1}
            autoPlaySpeed={3000}
            breakPoints={breakPoints}
          >
            {giftItems.map((item, index) => (

              <div className="featured-artists-card card">
                <h2 className='test'>{t('Obradujte_svoju_dragu_osobu_umjetninom')}</h2>
                <div className="artist-info">
                  <img className='image-test' src={item.imageUrl} alt={item.name} />
                  <h3>{item.name}</h3>
                  <p>{item.description}</p>
                  <button className="cta-button" onClick={() => handleButtonClick(item.id)}>
                  {t('Pogledaj_umjetninu')}
                  </button>
                </div>
              </div>
            ))}
          </Carousel>
        </div>

        <div className="mission-card card">
          <h2>{t('Misija_Galerija')}</h2>
          <div className="mission-info">
            <p className='mission-p'>{t('Nudimo_jedinstvenu_platform_na_kojoj_svi_umjetnici_mogu_slobodno_prikazati_svoje_radove_kao_i_ponuditi_ih_svojoj_publici')}</p>
            <button className="cta-button" onClick={() => handleButtonClick('/g')}>
              {t('Pogledajte_našu_galeriju')}
            </button>
          </div>
        </div>

        <div className="mission-card card">
          <h2>{t('Vizija_Galerija')}</h2>
          <div className="mission-info">
            <p className='mission-p'>Nudimo jedinstvenu platform na kojoj svi umjetnici mogu slobodno prikazati svoje radove, kao i ponuditi ih svojoj publici.</p>
            <button className="cta-button" onClick={() => handleButtonClick('/g')}>
              {t('Pogledajte_našu_galeriju')}
            </button>
          </div>
        </div>

        <div className="mission-card card">
          <h2>{t('Cilj_Galerija')}</h2>
          <div className="mission-info">
            <p className='mission-p'>Učiniti umjetnost dostupnom svima u našoj domovini!</p>
            <button className="cta-button" onClick={() => handleButtonClick('/g')}>
              {t('Pogledajte_našu_galeriju')}
            </button>
          </div>
        </div>




      </div>
      <div className="newslatter-card">
        <h2>{t('Prijavite_se_na_newsletter')}</h2>
        <NewsLetter />
      </div>

    </section>
  );
}


export default Home;
