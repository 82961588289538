import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Carousel from 'react-elastic-carousel'; // Or your preferred carousel library
import './ArtistProfile.css';
import { FaFacebookF, FaInstagram, FaBehance } from 'react-icons/fa';


function ArtistProfile() {
    const { artistId } = useParams();
    const location = window.location;
    const [artistData, setArtistData] = useState(location.state?.artist);
    const navigate = useNavigate(); // For back button functionality


    useEffect(() => {
        const API_URL = process.env.NODE_ENV === 'production'
            ? 'https://digitalnagalerija.ba'
            : 'http://localhost:3001';

        const fetchArtist = async () => {
            if (!artistData) {
                try {
                    const response = await fetch(`${API_URL}/api/artists`);
                    if (response.ok) {
                        const artistsData = await response.json();
                        const artist = artistsData.find(artist => artist.artistId === artistId);
                        if (artist) {
                            setArtistData(artist);
                        } else {
                            // Handle artist not found error
                            console.error('Artist not found');
                        }
                    } else {
                        throw new Error('Could not fetch artists');
                    }
                } catch (error) {
                    console.error('Error fetching artist:', error);
                }
            }
        };

        fetchArtist();
    }, [artistData, artistId]);

    if (!artistData) {
        return <div>Artist not found or error fetching data</div>; // Display error message
    }


    const handleBackClick = () => {
        navigate(-1); // Go back to the previous page
    };

    const socialMediaIcons = {
        facebook: <FaFacebookF />,
        instagram: <FaInstagram />,
        behance: <FaBehance />
    };

    const handleClick = (artistId) => {
        navigate(`/artwork/${artistId}`); // Assuming you have artist IDs
    };

    return (
        <section className="artist-profile">
            {/* Artist Name */}
            <button className="back-button" onClick={handleBackClick}>
                &larr;
            </button>
            
            <img
                src={artistData?.imageUrl}
                alt={artistData?.name}
                className="artist-background-image"
            />
            <h3 className="artist-popular-work">Popularni radovi</h3>
            {/* Carousel with Artist's Work */}
            <div className="artwork-carousel">
                <Carousel
                    showDots={false}
                    showArrows={false}  // Disable the built-in arrows
                    enableSwipe={true}
                    itemsToShow={3}
                >
                    {artistData?.artwork.map(artwork => (
                        <div key={artwork._id} className="carousel-image-container" onClick={() => handleClick(artwork._id)}>  {/* Wrap image in a container */}
                            <img
                                src={artwork.imageUrl}
                                alt={artwork.title}
                                className="carousel-image"
                            />
                            <div className="carousel-image-overlay">  {/* Add overlay */}
                                <p className="carousel-image-title">{artwork.title}</p>  {/* Title insartistIde overlay */}
                            </div>
                        </div>
                    ))}
                </Carousel>


            </div>
            <h2 className="artist-bio-name">{artistData?.name}</h2>
            <div className="artist-bio">
                <p>{artistData?.bio}</p>
            </div>
            <div className="social-media-card">
                <h3>Pogledajte umjetnika i na društvenim mrežama</h3>
                <ul className="social-links">
                    {artistData?.socialLinks && Object.entries(artistData.socialLinks).map(([platform, link]) => (
                        link && (
                            <li key={platform}>
                                <a href={link} target="_blank" rel="noopener noreferrer">
                                    {socialMediaIcons[platform]}
                                </a>
                            </li>
                        )
                    ))}
                </ul>
            </div>

            {/* Artist Biography */}
            <h2 className='comming-soon'>USKORO I NA DESKTOP PREGLEDU</h2>
        </section>
    );
}

export default ArtistProfile;