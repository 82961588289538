import React, { useState } from 'react';
import Menu from './Menu';
import Footer from './Footer';
import './Layout.css'
import hamburgerIcon from '../fonts/hamburger.png'
import logo from '../fonts/logo bez teksta bijeli.png'
import BackToTopButton from '../components/BackTop.js'
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

function Layout({ children }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const redirectHome = () => {
    console.log("it works")
    navigate('/');
  }

  return (
    <>
      <header>
        <button className="menu-toggle" onClick={toggleMenu}>
          <img src={hamburgerIcon} alt="Menu" />
        </button>
        <div className='logo-container'>

          <img onClick={redirectHome} src={logo} alt="Your Logo" className="logo" />
        </div>
      </header>

      {/* Pass isMenuOpen and toggleMenu to Menu */}
      <Menu isOpen={isMenuOpen} toggleMenu={toggleMenu} />

      <main>{children}</main>
      <BackToTopButton />
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={6000}
        toastStyle={{ backgroundColor: '#3b3b3b', color: '#f0f0f0', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)' }}
        hideProgressBar={true}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        draggablePercent={60}
        pauseOnHover
        closeButton={false}
        theme="dark"
      />
    </>
  );
}

export default Layout;
