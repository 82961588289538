// src/components/Menu.js
import React, { useState, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './Menu.css';
import Spacer from './Spacer';
import TranslationContext from '../context/TranslationContext';
import { useTheme } from '../context/ThemeToggle';
import { useTranslation } from 'react-i18next';


function Menu({ isOpen, toggleMenu }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { theme, toggleTheme } = useTheme();
  const { currentLanguage, toggleLanguage } = useContext(TranslationContext);

  const handleLogoClick = () => {
    toggleMenu(); // Close the menu if it's open
    navigate('/'); // Redirect to the homepage
  };

  const handleLinkClick = (path) => {
    toggleMenu(); // Close the menu first
    setTimeout(() => {
      navigate(path); // Navigate to the desired route after a short delay
    }, 300); // Adjust the delay as needed for smooth animation
  };


  return (
    <header>
      <nav className={`menu ${isOpen ? 'open' : ''}`}>
        <ul>
          <li><NavLink to="/" onClick={() => handleLinkClick("/")} className={`${isOpen ? 'fade-in' : ''}`}>{t('home')}</NavLink></li>
          <li><NavLink to="/onama" onClick={() => handleLinkClick("/onama")} className={`${isOpen ? 'fade-in' : ''}`}>{t('about_us')}</NavLink></li>
          <li><NavLink to="/g" onClick={() => handleLinkClick("/g")} className={`${isOpen ? 'fade-in' : ''}`}>{t('gallery')}</NavLink></li>
          <li><NavLink to="/blog" onClick={() => handleLinkClick("/blog")} className={`${isOpen ? 'fade-in' : ''}`}>{t('blog')}</NavLink></li>
          <li><NavLink to="/rd" onClick={() => handleLinkClick("/rd")} className={`${isOpen ? 'fade-in' : ''}`}>{t('submit_work')}</NavLink></li>
          <li><NavLink to="/sp" onClick={() => handleLinkClick("/sp")} className={`${isOpen ? 'fade-in' : ''}`}>{t('gift')}</NavLink></li>
          <li><NavLink to="/pu" onClick={() => handleLinkClick("/pu")} className={`${isOpen ? 'fade-in' : ''}`}>{t('artist_profiles')}</NavLink></li>
          <li><NavLink to="/kt" onClick={() => handleLinkClick("/kt")} className={`${isOpen ? 'fade-in' : ''}`}>{t('contact')}</NavLink></li>
          <li><NavLink to="/faq" onClick={() => handleLinkClick("/faq")} className={`${isOpen ? 'fade-in' : ''}`}>{t('faq')}</NavLink></li>
          <Spacer height={120} />
          <li className="translate-toggle">
            <button className={`switch ${currentLanguage}`} onClick={toggleLanguage}>
              <span className="slider">
                <span className="circle"></span>
                <span className="text en">EN</span>
                <span className="text bs">BS</span>
              </span>
            </button>
          </li>
{/*           <li className="translate-toggle">
            <button className={`switch ${theme}`} onClick={toggleTheme}>
              <span className="slider">
                <span className="circle"></span>
                <span className="text">Li</span>
                <span className="text">Da</span>
              </span>
            </button>
          </li> */}
          <li><NavLink to="/login" onClick={() => handleLinkClick("/login")} className={`${isOpen ? 'fade-in' : ''}`}>Login</NavLink></li>
        </ul>
      </nav>
    </header>
  );
}

export default Menu;
