import React, { useState, useRef } from 'react';
import './Kupi.css';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom'; 
import { toast } from 'react-toastify'; // Import for toast notifications
import 'react-toastify/dist/ReactToastify.css';


function Kupi({ artwork = {} }) {
    const navigate = useNavigate();
    const form = useRef();
    const [action, setAction] = useState(null); // 'buy' or 'ask'
    
    const mockedArtwork = {
        title: "Džehenemski sohbet",
        price: "500",
        artist: "Jane Doe", // Add artist information if needed
    };
    const [formData, setFormData] = useState({
        // Buy Form
        address: '',
        zipCode: '',
        city: '',
        fullName: '',
        phoneNumber: '',
        // Ask Form
        name: '',
        contact: '', // Email or phone
        question: '',
        title: mockedArtwork.title,
        price: mockedArtwork.price

    });


    // Use the mocked artwork if none is provided through props
    const artworkData = artwork.title ? artwork : mockedArtwork;

    const handleSubmit = (e) => {
        e.preventDefault();


        emailjs
            .sendForm('service_ms85pq1', 'template_aog96pa', form.current, 'itkoKotCnNMNFH2oM')
            .then(
                () => {
                    toast.success('Vaše pitanje je uspješno poslano!');
                    navigate('/');
                    console.log('SUCCESS!');
                },
                (error) => {
                    console.log('FAILED...', error.text);
                    toast.error('Došlo je do greške pri slanju pitanja. Molimo pokušajte ponovo.');
                },
            );
    };

    const handleSubmitBuy = (e) => {
        e.preventDefault();


        emailjs
            .sendForm('service_ms85pq1', 'template_8psokbw', form.current, 'itkoKotCnNMNFH2oM')
            .then(
                () => {
                    console.log('SUCCESS!');
                    navigate('/');
                    toast.success('Vaše pitanje je uspješno poslano!');
                },
                (error) => {
                    console.log('FAILED...', error.text);
                    toast.error('Došlo je do greške pri slanju pitanja. Molimo pokušajte ponovo.');
                },
            );
    };



    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleActionClick = (selectedAction) => {
        setAction(selectedAction);
    };

    return (
        <div className="buy-art-container">
            <h2>Kupite originalnu umjetnost</h2>
            <h2>{artworkData.title}</h2>
            <p>Cijena rada: {artworkData.price}</p>

            {!action && (
                <div className="action-buttons">
                    <button onClick={() => handleActionClick('buy')}>Kupi rad</button>
                    <button onClick={() => handleActionClick('ask')}>Postavi pitanje umjetniku</button>
                </div>
            )}

            {action === 'buy' && (
                
                <form ref={form} className="buy-form" onSubmit={handleSubmitBuy}>
                    <input type="text" name="address" placeholder="Adresa" value={formData.address} onChange={handleChange} />
                    <input type="text" name="zipCode" placeholder="Poštanski broj" value={formData.zipCode} onChange={handleChange} />
                    <input type="text" name="city" placeholder="Grad" value={formData.city} onChange={handleChange} />
                    <input type="text" name="fullName" placeholder="Puno ime" value={formData.fullName} onChange={handleChange} />
                    <input type="tel" name="phoneNumber" placeholder="Broj telefona" value={formData.phoneNumber} onChange={handleChange} />
                    <input type="hidden" name="title" value={formData.title} />
                    <input type="hidden" name="price" value={formData.price} />

                    <button type="submit">Kupi umjetninu</button>
                </form>
            )}

            {action === 'ask' && (
                <form ref={form} className="ask-form" onSubmit={handleSubmit}>
                    <input type="text" name="name" placeholder="Ime" value={formData.name} onChange={handleChange} />
                    <input type="text" name="contact" placeholder="Email ili Broj telefona" value={formData.contact} onChange={handleChange} />
                    <textarea name="question" placeholder="Vaše pitanje" value={formData.question} onChange={handleChange} />
                    <input type="hidden" name="title" value={formData.title} />
                    <input type="hidden" name="price" value={formData.price} />

                    <button type="submit">Kontaktiraj umjetnika</button>
                </form>
            )}
        </div>
    );
}




export default Kupi;