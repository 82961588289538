import React, { useState, useEffect } from 'react';
import BlogCard from './BlogItem.js'; // Import the BlogCard component
import './BlogItem.css';
import BackgroundVideo from '../components/BackgroundVideo.js';
import Spacer from '../components/Spacer.js';
import video from '../fonts/blogimages/artist.mp4';
import { useTranslation } from 'react-i18next';




function BlogPage() {

  const [blogPosts, setBlogPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();


  const API_URL = process.env.NODE_ENV === 'production'
    ? 'https://digitalnagalerija.ba'
    : 'http://localhost:3001';

  useEffect(() => {
    fetch(`${API_URL}/api/blogposts/`) // Fetch from /api/blogposts (same as the base router path)
      .then(res => res.json())
      .then(data => {
        setBlogPosts(data);
        setIsLoading(false);
        console.log('data', data);
      })
      .catch(error => console.error('Error fetching blog posts:', error));
  }, []);



  return (
    <>
      <BackgroundVideo video={video} />
      <h2 className="blog-artist">{t('artist_blog')}</h2>
      <p className="blog-p">{t('blog_welcome')}</p>
      <div className="blog-page">
        {blogPosts.map((blog, index) => (
          <BlogCard key={blog.name} blog={blog} reverse={index % 2 === 1} />
        ))}
      </div>

      <h2 className='comming-soon'>USKORO I NA DESKTOP PREGLEDU</h2>
    </>
  );
}

export default BlogPage;
