import React, { useEffect, useRef    } from 'react';
import './LoadingScreen.css'; // Import your CSS
import logo from '../fonts/logo bez teksta bijeli.png'; // Path to your logo image

function LoadingScreen() {
    const loadingScreenRef = useRef(null);
    useEffect(() => {
        const timer = setTimeout(() => {
            if (loadingScreenRef.current) { // Check if ref exists before fading out
                loadingScreenRef.current.classList.add('fade-out'); // Add the fade-out class
            }

            setTimeout(() => { // Add another timeout for complete removal
                if (loadingScreenRef.current) {
                    loadingScreenRef.current.style.display = 'none';
                }
            }, 1000);
        }, 3000);

        return () => clearTimeout(timer); // Clean up on unmount
    }, []);

    return (
        <div id="loading-screen">
            <img src={logo} alt="Logo" className="loading-logo" />
        </div>
    );
}

export default LoadingScreen;
